import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';

import {
    contextReducer,
    documentReducer,
    referenceReducer
} from 'metrix-common';

import { workflowReducer } from 'metrix-workflow';

import securityReducer from '../reducers/security';
import policyReducer from '../reducers/policy';
import productReducer from '../reducers/product';
import templateReducer from '../reducers/template';
import agentReducer from '../reducers/agent';
import storefrontReducer from '../reducers/storefront';
import clientCompanyReducer from '../reducers/clientCompany';
import clientReducer from '../reducers/client';
import brokerCompanySignupReducer from '../reducers/brokerCompanySignup';
import brokerCompanySettingsReducer from '../reducers/brokerCompanySettings';

export default function configureStore(initialState = {}) {

    const reducers = {
        context: contextReducer,
        security: securityReducer,
        storefront: storefrontReducer,
        workflow: workflowReducer,
        policy: policyReducer,
        product: productReducer,
        products: productReducer,
        templateConfig: templateReducer,
        agent: agentReducer,
        clientCompany: clientCompanyReducer,
        client: clientReducer,
        reference: referenceReducer,
        document: documentReducer,
        brokerCompanySignup: brokerCompanySignupReducer,
        brokerCompanySettings: brokerCompanySettingsReducer
    };

    const sagaMiddleware = createSagaMiddleware();

    const middleware = [
        sagaMiddleware
    ];

    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers({
        ...reducers
    });

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    store.injectedReducers = {};
    store.runSaga = sagaMiddleware.run;
    store.injectedSagas = {};

    return store;
}
